import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const ServerErrorPage = () => {
  const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteDescription
            author
            siteUrl
            siteImage
          }
        }
      }
  `)
  
  return (
    <Layout>
      <SEO title="404: Not found" data={data.site} />
      <div className='container text-center my-5'>
        <h1>Server Error</h1>
        <p>We had a server error. Please try again or <a href='mailto:info@datamade.us'>contact us</a> if the error persists.</p>
      </div>
    </Layout>
  )
}

export default ServerErrorPage
